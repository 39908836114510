import React, { useState } from "react";
import "../css/Common.css";
import { NavLink, Link } from "react-router-dom";
import logo from "../images/logo.png";
// import { FaCaretDown } from "react-icons/fa";
// import { FaCaretUp } from "react-icons/fa";

const Header = () => {
  // const [menu, setMenu] = useState(false);
  function showNavbar() {
    let navbar = document.querySelector(".header_wrapper");
    let navList = document.querySelector(".nav_list");
    navList.classList.toggle("v-class");
    navbar.classList.toggle("h-nav");
  }
  return (
    <>
      <div className="header_wrapper h-nav">
        <nav>
          <div className="logo_wrapper ">
            {/* <p>FinoSol</p> */}
            {/* <Link to="/"> */}
              <img src={logo} alt="" />
              <p className="ml5">SalaryOnTime</p>
            {/* </Link> */}
          </div>
          {/* <ul className="nav_list v-class">
            <li>
              <NavLink to="home">Home</NavLink>
            </li>
            <li>
              <NavLink to="about">About</NavLink>
            </li>
            <li>
              <NavLink to="services">Services</NavLink>
            </li>
            <li>
              <NavLink to="contact">Contact</NavLink>
            </li>
            <li>
              <NavLink to="apply-now" className="apply_now_button_link">
                Apply Now
              </NavLink>
            </li>
            <li>
              <NavLink to="repayloan" className="apply_now_button_link repay_loan_button">
                Repay Loan
              </NavLink>
            </li>
          </ul>  */}
          <div className="burger" onClick={showNavbar}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
