import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Header from "./components/Header";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/Footer";
import ApplyForLoan from "./pages/ApplyForLoan";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const showmessage = async (message) => {
    try {
      var x = document.getElementById("snackbar");
      x.className = "show";
      x.innerText = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  function RedirectWithQuery() {
    const location = useLocation();
    const queryParams = location.search;
    // Redirect to the apply-now page with the existing query parameters
    return <Navigate to={`/apply-now${queryParams}`} />;
  }

  return (
    <>
      <Routes>
        <Route path="/apply-now" element={<ApplyForLoan showmessage={showmessage} />} />
        <Route path="/*" element={<RedirectWithQuery />} />
      </Routes>
    </>
  );
};

export default App;
